import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {SocialTagsService} from './core/services/social-tags-service.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  title = 'core-front';

  constructor(
    private meta: Meta,
  ) {
    if (environment.noindex) {
      this.meta.updateTag({ name: 'robots', content: 'noindex' });
    }
  }
}
